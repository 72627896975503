<template>
  <v-container class="pa-0 category-tree-container">
    <div class="header d-flex justify-space-between align-center">
      <v-tabs
        v-model="selectedTab"
        hide-slider
        active-class="activeTab"
        color="primary"
      >
        <v-tab key="categories"
          ><h3>{{ $t("categories.title") }}</h3>
        </v-tab>
        <v-tab key="info">
          <h3>{{ $t("info.title") }}</h3>
        </v-tab>
      </v-tabs>
      <v-btn class="close-btn" small text @click="$emit('close')">
        <v-icon small>icon-close</v-icon>
      </v-btn>
    </div>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item key="categories">
        <v-list dense class="category-tree">
          <v-list-group
            v-for="category in categories"
            :key="category.categoryId"
            class="category1 px-0"
          >
            <template v-slot:activator>
              <v-list-item-icon
                v-if="category.metaData && category.metaData.category_info.ICON"
              >
                <v-img :src="category.metaData.category_info.ICON"></v-img>
              </v-list-item-icon>
              <v-list-item-title>
                <router-link
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  }"
                  event
                  @click.native.prevent="categoryFirstLevelClicked(category)"
                >
                  {{ category.name }}</router-link
                ></v-list-item-title
              >
            </template>
            <template v-slot:appendIcon>
              <v-icon small>mdi-chevron-down</v-icon>
            </template>
            <template v-for="category2 in category.categories">
              <!-- <v-list-group
                sub-group
                color="#033"
                class="category2"
                v-if="category2.categories"
                :key="category2.categoryId"
              >
                <template v-slot:activator>
                  <v-list-item-title>
                    <router-link
                      :to="{
                        name: 'Category',
                        params: { pathMatch: category2.slug }
                      }"
                      event
                      @click.native.prevent="nop"
                      >{{ category2.name }}
                    </router-link>
                  </v-list-item-title>
                </template>

                <v-list-item
                  v-for="category3 in category2.categories"
                  :key="category3.categoryId"
                  link
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category3.slug }
                  }"
                  color="grey lighten-3"
                  class="category3"
                >
                  <v-list-item-title>{{ category3.name }}</v-list-item-title>
                </v-list-item>
              </v-list-group> -->
              <v-list-item
                link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                color="grey lighten-3"
                class="category2"
                :key="category2.categoryId"
              >
                <v-list-item-title class="font-weight-bold">{{
                  category2.name
                }}</v-list-item-title>
              </v-list-item>
            </template>
            <category-block
              v-if="categoryOpened"
              :target="categoryOpened"
              position="menu"
              class="category-block category-block-menu pa-3"
              :container="false"
            />
          </v-list-group>
        </v-list>
      </v-tab-item>
      <v-tab-item key="info">
        <v-list class="info-tab">
          <div v-for="info in infos" :key="info.ID">
            <v-list-item-title
              v-if="info.templateNavItems.length <= 0"
              class="category1"
              :class="info.class"
            >
              <a :href="info.itemUrl" class="px-4" event>
                {{ info.name }}</a
              ></v-list-item-title
            >
            <v-list-group v-else class="category1" prepend-icon="">
              <template v-slot:activator>
                <v-list-item-title>
                  <a :href="info.itemUrl" class="px-4" event>
                    {{ info.name }}</a
                  >
                </v-list-item-title>
              </template>
              <template v-slot:appendIcon>
                <v-icon small>mdi-chevron-down</v-icon>
              </template>

              <v-list-item
                v-for="(child, idx) in info.templateNavItems"
                :key="idx"
                link
                :href="child.itemUrl"
              >
                <v-list-item-title class="">{{ child.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import deliveryReactive from "~/mixins/deliveryReactive";

import categoryService from "@/commons/service/categoryService";

import { mapState, mapActions } from "vuex";

export default {
  name: "CategoryTree",
  mixins: [deliveryReactive],
  props: ["infos"],
  data() {
    return {
      selectedTab: null,
      expandedKeys: [],
      selectedKey: null,
      categoryOpened: null,
      firstLevelCategories: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    })
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    async fetchCategory(cat) {
      let vm = this;
      if (vm.firstLevelCategories[cat.categoryId] != undefined) {
        vm.categoryOpened = vm.firstLevelCategories[cat.categoryId];
      } else {
        let result = await categoryService.getCategoryBySlug(cat.slug);
        vm.firstLevelCategories[cat.categoryId] = result;
        vm.categoryOpened = result;
      }
    },
    close() {
      this.$emit("close");
    },
    categoryFirstLevelClicked(cat) {
      this.fetchCategory(cat);
    }
  },
  created() {
    this.reload();
  }
};
</script>
