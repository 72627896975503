<template>
  <div>
    <div v-if="full" class="mt-4 mb-md-6 pa-3 pa-sm-0">
      <div class="notes pt-4">
        <v-textarea
          outlined
          no-resize
          auto-grow
          v-model="orderComment"
          :maxlength="1000"
          :label="$t('cart.notes.label')"
          :placeholder="$t('cart.notes.placeholder')"
          @blur="setCartInfo({ order_comment: tempOrderComment })"
          dense
          depressed
          rows="1"
        ></v-textarea>
      </div>
    </div>
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.xsOnly && full"
      class="table-header px-4"
    >
      <v-col cols="5" class="d-flex justify-start">
        {{ $t("checkout.tableHeader.product") }}
      </v-col>

      <v-col cols="2" sm="2" lg="2" xl="2">
        &nbsp;
      </v-col>
      <v-col cols="3" class="d-flex justify-center">
        {{ $t("checkout.tableHeader.quantity") }}
      </v-col>
      <v-col cols="2" class="d-flex justify-center text-center">
        {{ $t("checkout.tableHeader.totalPrice") }}
      </v-col>
    </v-row>
    <v-list
      id="cartItemList"
      flat
      subheader
      dense
      class="cart-item-list"
      :class="{ 'checkout-item-list': full }"
    >
      <!-- three-line -->

      <template v-if="!full">
        <!-- <v-divider /> -->
        <v-subheader
          class="cart-item-count mx-5 d-flex justify-space-between mb-2"
        >
          <CartItemCount :count="cart.totalItems" />
          <v-btn
            elevation="0"
            class="empty-cart-btn"
            :disabled="cart.totalItems == 0"
            @click="emptyCartConfirm"
            >Svuota il carrello</v-btn
          >
        </v-subheader>
        <!-- <v-divider /> -->
      </template>

      <div :class="{ 'cart-item-list-no-checkout': !full }">
        <div class="flex align-center" :key="renderKey">
          <div v-for="cartItem in sortedCartItems" :key="cartItem.cartItemId">
            <CartItem :item="cartItem" :full="full" />
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import CartItem from "./CartItem.vue";
import CartItemCount from "./CartItemCount.vue";

import { mapActions, mapState } from "vuex";

import reduce from "lodash/reduce";
import get from "lodash/get";

export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: { full: { type: Boolean, default: false } },
  data() {
    return {
      panel: [],
      showSectors: null,
      tempOrderComment: null,
      sortedCartItems: null,
      renderKey: 1
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (
            get(cartItem, "cartItemInfo.accept_alternatives", "true") == "false"
          ) {
            value = false;
          }
          return value;
        });
        reduce(
          this.cart.cartItems,
          function(value, cartItem) {
            return (value && cartItem.cartItemInfo.accept_alternatives) || true;
          },
          true
        );
        // this.cart.cartItems.forEach(cartItem => {
        //   if (
        //     get(cartItem, "cartItemInfo.accept_alternatives", "true") ==
        //     "false"
        //   ) {
        //     value = false;
        //   }
        //   return value;
        // });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        this.tempOrderComment = value;
      }
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    sortCartItems() {
      // eslint-disable-next-line no-unused-vars
      this.sortedCartItems = [...this.cart.cartItems].sort((a, b) =>
        a.product.productInfos.PRODUCT_NATURE_ID === "8" ? 1 : -1
      );
      this.renderKey++;
    }
  },
  mounted() {
    this.showSectors = global.config.showSectors;
    if (global.config.showSectors) {
      if (this.cart && this.cart.sectors) {
        this.panel = this.cart.sectors.map((k, i) => i);
      }
    }
    this.sortCartItems();
  },
  watch: {
    cart() {
      if (global.config.showSectors) {
        if (this.cart && this.cart.sectors) {
          this.panel = this.cart.sectors.map((k, i) => i);
        }
      }
      this.sortCartItems();
    }
  }
};
</script>
