import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";
import store from "./store";

const routes = [
  {
    path: "/",
    name: "Home",
    props: true,
    component: () => import("@/views/catalog/Category.vue"),
    meta: {
      slug: "homepage-category-block-b2b"
    }
  },
  ...base,
  ...wordpress,
  ...profile,
  ...checkout,
  ...store,
  ...catalog
];

export default routes;
