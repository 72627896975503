let routes = [
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/store-list",
    component: () => import("@/views/store/Warehouse.vue"),
    meta: {
      slug: "store-locator"
    },
    children: [
      {
        path: "/store-locator",
        name: "WarehouseList",
        meta: { breadCrumb: "Punti Vendita" },
        component: () => import("@/views/store/WarehouseList.vue")
      },
      {
        path: "/store-locator/:warehouseId",
        name: "WarehouseDetail",
        meta: { breadCrumb: "Punti Vendita" },
        component: () => import("@/views/store/WarehouseDetail.vue")
      }
    ]
  }
];

export default routes;
