<template>
  <div class="cart-item py-2">
    <router-link
      :to="
        item.product.productInfos.PRODUCT_NATURE_ID !== '8'
          ? {
              name: 'Product',
              params: { productSlug: item.product.slug }
            }
          : ''
      "
    >
      <v-row align="center" class="pb-4">
        <v-col cols="9" sm="5" class="d-flex flex-row align-center">
          <img
            :style="{ width: '60px', height: '60px' }"
            :src="item.product.mediaURL"
            :alt="'Immagine prodotto'"
          />
          <div class="cart-item-content">
            <div class="descripion">
              <div class="text-wrap font-weight-bold">
                {{ item.product.name }}
              </div>
              <div>
                {{ item.product.description }}
              </div>
              <div>
                {{ item.product.shortDescr }}
              </div>
              <div class="cart-item-info">
                <em>{{ item.cartItemInfo.size }}</em>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="3" sm="2" v-if="full">
          <ProductPrice
            :product="item.product"
            :isPackage="item.cartItemInfo.is_package"
          ></ProductPrice>
        </v-col>
        <v-col cols="8" :sm="full ? 3 : 5" class="d-flex justify-center">
          <div :class="'actions d-flex align-center'">
            <div
              class="qty-wrap rounded-pill"
              :class="{ 'not-empty': quantity > 0 }"
            >
              <template v-if="!showQntyInput">
                <a
                  role="button"
                  class="minus"
                  @click.prevent.stop="
                    minus(true, { infos: item.cartItemInfo })
                  "
                  @mousedown.stop
                  v-if="item.product.productInfos.PRODUCT_NATURE_ID !== '8'"
                >
                  <v-icon>mdi-minus</v-icon>
                </a>
                <div
                  class="val-cnt"
                  @click.stop.prevent=""
                  @dblclick="
                    item.cartItemInfo.is_package == 'true'
                      ? toggleShowInput()
                      : () => {}
                  "
                  v-long-press="300"
                  @long-press-start="
                    item.cartItemInfo.is_package == 'true'
                      ? toggleShowInput()
                      : () => {}
                  "
                  @mousedown.stop
                >
                  <span class="val">{{ quantity }} {{ unit }}</span>
                  <span class="small">{{ quantityPerUnit }}</span>
                  <span
                    class="package small"
                    v-if="item.cartItemInfo.is_package == 'true'"
                  >
                    {{ packageCount }}
                  </span>
                </div>
                <a
                  role="button"
                  class="plus"
                  @click.prevent.stop="plus(true, { infos: item.cartItemInfo })"
                  @mousedown.stop
                  v-if="item.product.productInfos.PRODUCT_NATURE_ID !== '8'"
                >
                  <v-icon>mdi-plus</v-icon>
                </a>
              </template>
              <template v-else>
                <a
                  role="button"
                  class="close minus"
                  @click.stop.prevent="toggleShowInput(false)"
                  @mousedown.stop
                >
                  <v-icon>mdi-close</v-icon>
                </a>
                <v-tooltip
                  bottom
                  :open-on-hover="false"
                  :open-on-click="true"
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="qntyInput"
                      ref="manualInput"
                      class="manual-input"
                      hide-details
                      height="52"
                      autofocus
                      outlined
                      dense
                      v-mask="'###'"
                      type="number"
                      @click.stop.prevent=""
                      @keyup.enter.prevent="
                        toggleShowInput(true, {
                          infos: { is_package: item.cartItemInfo.is_package }
                        })
                      "
                      @keyup.esc="toggleShowInput(false)"
                      @mousedown.stop
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span
                    class="d-block qntity-tooltip"
                    v-if="!modulus(qntyInput)"
                    v-html="
                      $t('product.quantity.confirmQntity', {
                        qntyInput: this.qntyInput
                      })
                    "
                  >
                  </span>
                  <span
                    class="d-block qntity-tooltip"
                    v-if="
                      modulus(qntyInput) &&
                        parseInt(qntyInput) <=
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMin', {
                        lowerInput: this.lowerInput
                      })
                    "
                  >
                  </span>

                  <span
                    class="qntity-tooltip"
                    v-if="
                      modulus(qntyInput) &&
                        parseInt(qntyInput) >
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMinMax', {
                        lowerInput: this.lowerInput,
                        higherInput: this.higherInput
                      })
                    "
                  >
                  </span>
                </v-tooltip>
                <a
                  role="button"
                  class="check plus"
                  @click.stop.prevent="
                    toggleShowInput(true, {
                      infos: { is_package: item.cartItemInfo.is_package }
                    })
                  "
                  @mousedown.stop
                  v-if="item.product.productInfos.PRODUCT_NATURE_ID !== '8'"
                >
                  <v-icon>mdi-check</v-icon>
                </a>
              </template>
            </div>
          </div>
        </v-col>
        <v-col cols="4" sm="2" class="d-flex flex-row">
          <div class="gross-total price">
            {{ $n(item.grossTotal, "currency") }}
          </div>
          <v-btn
            icon
            class="remove-cart-item ml-4"
            aria-label="Rimuovi dal carrello"
            @click.prevent="remove"
            @mousedown.stop
          >
            <v-icon color="primary">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </router-link>
    <v-row
      v-if="full && item.product.productInfos.PRODUCT_NATURE_ID !== '8'"
      no-gutters
      align-content="center"
      class="alternatives mt-2"
    >
      <v-textarea
        outlined
        no-resize
        auto-grow
        v-model="userNotes"
        @blur="
          setCartItemInfo({
            items: item,
            name: 'user_notes',
            value: tempUserNotes
          })
        "
        :maxlength="1000"
        :label="$t('cart.notes.itemNote')"
        rows="1"
        dense
        depressed
      ></v-textarea>
    </v-row>

    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="green darken-1"
            text
            @click="removeDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            depressed
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.manual-input {
  width: 60px;
}
</style>
<script>
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice.vue";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";

import LongPress from "vue-directive-long-press";

export default {
  name: "CartItem",
  props: ["item", "full"],
  mixins: [productMixin],
  components: { ProductPrice },
  directives: {
    mask: mask,
    "long-press": LongPress
  },
  data() {
    return { removeDialog: false, tempUserNotes: "" };
  },
  computed: {
    product() {
      return this.item.product;
    },
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        const logisticPackagingItems =
          this.product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
          this.product.logisticPackagingItems;
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          logisticPackagingItems > 0 &&
          this.item.quantity % logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    },
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    },
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount"
    })
  },
  methods: {
    ...mapActions({ setCartItemInfo: "cart/setCartItemInfo" }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      if (
        this.item.product.productInfos &&
        this.item.product.productInfos.PRODUCT_NATURE_ID &&
        this.item.product.productInfos.PRODUCT_NATURE_ID === "8"
      ) {
        global.EventBus.$emit("removeGift", this.item);
      } else {
        this.removeItem(this.item);
      }
    }
  }
};
</script>
