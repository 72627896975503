import "./plugins";
import "./config";
import "vuetify-dialog/dist/vuetify-dialog.css";

import App from "./App.vue";
import TextHighlight from "vue-text-highlight";
import Vue from "vue";
import VuetifyDialog from "vuetify-dialog";

import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import ebsn from "~/functions";
import "./scss/app.scss";
import blocks from "@/components/categoryBlock/categoryBlock";

import { showToaster, showConfirm, showNotification } from "~/notifications";
Vue.config.productionTip = false;

global.EventBus = new Vue({
  i18n
});

Vue.component("text-highlight", TextHighlight);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
    router,
    i18n
  },
  confirm: {
    actions: {
      false: "No",
      true: {
        text: "Si",
        color: "primary"
      }
    },
    icon: true
  }
});
Vue.use(ebsn);
Vue.use(blocks);

// && process.env.NODE_ENV === "production"
// if (Vue.analytics.fbq) {
//   Vue.analytics.fbq.init(process.env.VUE_APP_FB_PIXEL);
// }
Vue.mixin({
  computed: {
    $platform_is_cordova: () => typeof cordova !== "undefined"
  }
});

Vue.prototype.$dialog.clearDialogs = () => {
  global.config.dialogs.forEach(_dialog => _dialog.remove());
  global.config.dialogs = [];
};

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,

  render: h => h(App)
}).$mount("#app");

global.vm = vm;

const toastedOption = {
  position: "bottom-center",
  duration: 3000,
  iconPack: "mdi",
  theme: "bubble",
  keepOnHover: true,
  action: {
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
};

global.EventBus.$on("error", payload => {
  showToaster("error", payload, toastedOption);
});
global.EventBus.$on("warning", payload => {
  showToaster("warning", payload, toastedOption);
});
global.EventBus.$on("success", payload => {
  showToaster("success", payload, toastedOption);
});

global.EventBus.$on("confirm", showConfirm);

global.EventBus.$on("notification", showNotification);

// global.EventBus = global.vm;
// AnalyticsService.configureCustomMap();
// Vue.prototype.$dialog.layout("default", DialogLayout);
