<template>
  <v-container class="fast-search-menu">
    <v-layout fill-height column>
      <h3
        class="mt-4 mb-2 title secondary--text font-weight-bold text-center"
        v-if="category && category.metaData && category.metaData.category_info"
      >
        {{ category.metaData.category_info.TITLE }}
      </h3>
      <p
        class="selector-subitle text-center"
        v-html="category.metaData.category_info.DESCRIPTION"
        v-if="
          category &&
            category.metaData &&
            category.metaData.category_info &&
            category.metaData.category_info.DESCRIPTION
        "
      ></p>
      <div class="form-container d-flex justify-center">
        <v-form
          @submit.prevent.stop="addProduct"
          class="d-flex flex-column justify-center"
        >
          <v-text-field
            v-model="searchString"
            outlined
            dense
            full-width
            label="Inserisci il prodotto da aggiungere"
            append-icon="mdi-plus"
            @click:append="addProduct"
            @keyup.enter.stop.prevent="addProduct"
          ></v-text-field>
          <v-list class="pt-0 product-list">
            <v-list-item v-for="product in productList" :key="product">
              <v-list-item-title>{{ product }}</v-list-item-title>
              <v-list-item-action @click="removeFastSearchWord(product)">
                <v-icon>mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="d-flex justify-center">
            <v-btn
              depressed
              large
              color="mt-5 mb-5 secondary search"
              @click="fastSearch"
              >CERCA TRA I PRODOTTI
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.fast-search-menu {
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-image: none !important;
  }
  .form-container {
    .v-form {
      width: 420px;
    }
    .v-btn.search {
      max-width: 200px;
    }
    .v-input {
      .v-icon {
        padding: 4px;
        font-size: 20px;
        font-weight: bold;
        color: $white !important;
        border-radius: 50%;
        background-color: var(--v-primary-base);
      }
    }
    .theme--light.v-list {
      background: transparent;
    }
    .v-list {
      .v-list-item {
        border-bottom: 1px solid var(--v-grey-lighten2);
      }
      .v-icon {
        font-size: 20px;
        font-weight: bold;
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
<script>
import CategoryService from "~/service/categoryService";
import { mapActions, mapState } from "vuex";

export default {
  name: "FastSearchMenu",
  data() {
    return {
      searchString: null,
      category: {}
    };
  },
  computed: {
    ...mapState({
      productList: state => state.wordsSearched.fastSearchList
    }),
    getItems() {
      return this.search ? this.items : this.commonSearch;
    }
  },
  methods: {
    ...mapActions({
      addFastSearchWord: "wordsSearched/addFastSearchWord",
      removeFastSearchWord: "wordsSearched/removeFastSearchWord"
    }),
    fastSearch() {
      console.log("submit called");
      this.$router.push({
        name: "FastSearch",
        query: { q: this.productList }
      });
      this.$emit("submit", true);
    },
    addProduct() {
      if (this.searchString) {
        this.addFastSearchWord(this.searchString);
        // this.productList.push(this.searchString);
        this.searchString = null;
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "fast-search-popup"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.fetchCategory();
  }
};
</script>
