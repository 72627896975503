<template>
  <v-card class="fast-payment-card" flat v-if="isAuthenticated">
    <v-card-title class="text-h5 line-height-1 primary--text font-weight-bold">
      {{ $t("cart.fastPayment.title") }}
    </v-card-title>
    <CartInfoWrapper class="mx-2" />
    <!-- <div class="pa-3">
      <PaymentInvoice
        @billingFormCompleted="validForm"
        :full="true"
        class="mt-4"
      />
    </div> -->

    <v-divider class="my-3" />

    <v-card-actions v-if="!loading">
      <v-row no-gutters justify="space-between">
        <v-btn outlined color="secondary" depressed @click="close"
          >{{ $t("common.back") }}
        </v-btn>
        <v-btn
          :disabled="!canProceed"
          color="secondary"
          v-on:click="buy"
          depressed
        >
          {{ $t("paymentTypeList.confirm") }}
        </v-btn>
      </v-row>
    </v-card-actions>
    <ResponseMessage :response="response" class="pa-3" />
  </v-card>
</template>

<style lang="scss">
.fast-payment-card {
  .list {
    .cart-info-button.v-btn--disabled {
      color: $secondary !important;
    }
  }
}
.rmv-card {
  border: none !important;
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import CartInfoWrapper from "@/components/cart/CartInfoWrapper.vue";

import { payment } from "~/components/payment/gateways/gatewayUtilsService";

import PaymentTypeService from "~/service/paymentTypeService";

import creditCard from "~/mixins/creditCart";

import CartService from "~/service/cartService";

import { mapActions, mapGetters } from "vuex";
// import PaymentInvoice from "../payment/PaymentInvoice.vue";
import login from "~/mixins/login";

export default {
  mixins: [creditCard, login],
  data() {
    return {
      error: null,
      selectedPaymentAuthId: null,
      paymentAuthSelected: null,
      paymentAuthList: null,
      prebilledAmount: null,
      response: {},
      loading: true,
      canProceed: true
    };
  },
  components: {
    ResponseMessage,
    CartInfoWrapper
    // PaymentInvoice
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      lockCart: "cart/lockCart"
    }),
    close() {
      this.$emit("submit", false);
    },
    goToPaymentMethods() {
      this.$router.push("/profile/payment-method");
      this.close();
    },
    setPaymentAuthSelected(pa) {
      this.paymentAuthSelected = pa;
    },
    async removePaymentAuth(paymentAuthId) {
      await PaymentTypeService.deletePaymentAuth(paymentAuthId);
      let paymentAuthList = await CartService.getPaymentAuthList(12);
      this.paymentAuthSelected = paymentAuthList.find(p => {
        return p.active === "1" && p.defaultAuth === true;
      });
      this.paymentAuthList = paymentAuthList.filter(p => {
        return p.active === "1";
      });
      if (this.paymentAuthSelected == null) this.paymentAuthList[0];
    },
    async getPrebilledAmount() {
      this.prebilledAmount = await CartService.getPreBilledAmount(
        this.paymentAuthSelected.paymentTypeId ||
          global.config.defaultPaymentTypeId
      );
    },
    async buy() {
      if (await this.needAddress()) {
        if (await this.needTimeslot()) {
          let vm = this;
          vm.loading = true;
          try {
            let cartLocked = await vm.lockCart();
            if (cartLocked) {
              console.log("ggg - cart locked, trying buy");
              let cart = await vm.buyCart({
                paymentTypeId: 12
              });
              if (cart) {
                vm.payResponseOk(cart);
              } else {
                this.$router.push("/payment-error");
              }
            }
          } catch (err) {
            vm.response = err;
          } finally {
            vm.loading = false;
            this.$emit("submit", true);
          }
        }
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "post", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    }
  },
  async mounted() {
    let paymentAuthList = await CartService.getPaymentAuthList(12);
    this.loading = false;

    if (paymentAuthList.length > 0) {
      //TEST MODE
      // paymentAuthList = [
      //   {
      //     active: "1",
      //     creditCard: "525599******9992",
      //     creditCardCircuit: "",
      //     creditCardName: "",
      //     defaultAuth: true,
      //     expireDate: "1225",
      //     params: "",
      //     paymentTypeId: 2,
      //     userId: 149482,
      //     userPaymentAuthId: 733
      //   }
      // ];
      this.paymentAuthSelected = paymentAuthList.find(p => {
        return p.active === "1" && p.defaultAuth === true;
      });
      this.paymentAuthList = paymentAuthList.filter(p => {
        return p.active === "1";
      });
      if (this.paymentAuthSelected == null) this.paymentAuthList[0];

      this.getPrebilledAmount();
    }
  }
};
</script>
