<template>
  <v-app-bar app color="primary" dark clipped-left :class="classCordova">
    <!-- barra navigazione desktop -->
    <v-container fluid class="d-flex flex-column px-0 pb-0">
      <v-row no-gutters class="nav px-2 px-sm-4">
        <v-col
          cols="4"
          sm="1"
          class="d-flex d-sm-none justify-start align-center p-sm-auto"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
            :to="{ path: $routerHistory.previous().path }"
            elevation="0"
            x-small
            class="back-button"
            aria-label="Torna Indietro"
          >
            <v-icon large elevation="0">mdi-chevron-left</v-icon>
          </v-btn>
          <v-app-bar-nav-icon
            @click.prevent.stop="drawerLeft = !drawerLeft"
            class="drawer-right"
          >
            <template v-slot:label>
              <v-btn
                color="secondary"
                icon
                small
                aria-label="Apri menu categorie"
              >
                <v-icon medium>$menu</v-icon>
              </v-btn>
            </template>
          </v-app-bar-nav-icon>
        </v-col>

        <v-col
          cols="4"
          sm="1"
          class="order-1 d-flex justify-center align-center"
        >
          <router-link :to="{ name: 'Home' }" class="">
            <img src="/logo.svg" alt="Logo Eurospin" class="header-logo" />
          </router-link>
        </v-col>

        <!-- CAMPO DI RICERCA (non visibile su phone, spostato nella BottomNavBar) -->
        <v-col
          cols="12"
          sm="7"
          md="5"
          lg="6"
          class="search pa-0 pl-sm-8 d-flex flex-row align-center order-last order-sm-2 my-2 my-sm-0"
        >
          <SearchInput />
          <v-btn
            elevation="0"
            text
            width="70"
            :height="$vuetify.breakpoint.xsOnly ? '38' : '47'"
            outlined
            class="ml-2 no-border"
            @click="openFastSearchDialog"
          >
            <div class="d-flex flex-column justify-center align-center">
              <div class="label">
                <span>
                  {{ $t("navbar.fastShopping") }}
                </span>
              </div>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="4" md="6" lg="5" class="d-flex justify-end pr-1 order-3">
          <!-- NON AUTENTICATO -->
          <div class="not-auth profile-nav justify-end" v-if="!isAuthenticated">
            <v-btn @click.stop="handleLogin()" icon color="white">
              <div class="d-flex flex-column justify-center align-center">
                <v-icon :large="$vuetify.breakpoint.smAndUp">$profile</v-icon>
              </div>
            </v-btn>
            <v-btn icon color="white" @click.stop="handleLogin()">
              <v-icon :large="$vuetify.breakpoint.smAndUp">$cart</v-icon>
            </v-btn>
          </div>

          <!-- AUTENTICATO -->
          <div class="cart-navbar profile-nav justify-center" v-else>
            <!-- CONSEGNA -->
            <v-row no-gutters class="pl-5">
              <v-btn icon small class="mt-1 d-none d-xl-flex">
                <v-img src="/consegna-casa.svg"></v-img>
              </v-btn>
              <div class="d-none d-md-flex flex-column">
                <v-btn
                  class="cart-info-button bold"
                  :disabled="!editableAddress"
                  text
                  x-small
                  color="white"
                  elevation="0"
                  @click="openAddressSelector"
                >
                  <span>{{ shippingAddress }} </span>
                  <v-icon color="secondary" right>$edit</v-icon>
                </v-btn>
                <v-btn
                  class="cart-info-button"
                  :disabled="!editableTimeslot"
                  text
                  x-small
                  color="white"
                  elevation="0"
                  @click="handleEditTimeslot"
                >
                  <span>{{ timeslot }}</span>
                  <v-icon color="secondary" right>$edit</v-icon></v-btn
                >
              </div>
            </v-row>
            <!-- LISTE-->
            <v-btn
              aria-label="Vai alle liste dei preferiti"
              class="profile-nav d-none d-sm-flex mx-2"
              to="/profile/lists"
              icon
              color="white"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon :large="$vuetify.breakpoint.smAndUp"
                  >mdi-heart-outline</v-icon
                >
              </div>
            </v-btn>

            <!-- PROFILO -->
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on.stop="on"
                  icon
                  color="white"
                  class="mx-2"
                >
                  <v-icon :large="$vuetify.breakpoint.smAndUp">$profile</v-icon>
                </v-btn>
              </template>
              <MenuNavigator class="account-menu" />
            </v-menu>

            <!-- CARRELLO -->
            <v-badge
              :content="totalItems"
              :value="totalItems > 0"
              :key="totalItems"
              transition="bounce"
              color="secondary"
              overlap
            >
              <v-btn
                text
                color="white"
                width="auto"
                @click.stop="openCart()"
                class="pa-0"
              >
                <div class="d-flex flex-row justify-center align-center">
                  <v-icon :large="$vuetify.breakpoint.smAndUp">$cart</v-icon>
                  <div class="ml-2 d-none d-md-flex flex-column">
                    <div style="font-weight: 400;text-transform:none;">
                      Carrello
                    </div>
                    <div class="label">
                      <div>{{ $n(cart.totalPrice, "currency") }}</div>
                    </div>
                  </div>
                </div>
              </v-btn>
            </v-badge>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="d-none d-sm-flex links align-center px-4 py-1">
        <v-btn
          class="drawer"
          @click.prevent.stop="drawerLeft = !drawerLeft"
          dense
          color="secondary"
        >
          <v-icon medium class="mr-2">$menu</v-icon>
          {{ $t("navbar.products") }}
        </v-btn>
        <div class="lower-links d-flex flex-row align-center">
          <div v-for="(link, index) in links" v-bind:key="index">
            <a
              v-if="link.templateNavItems.length == 0"
              class="px-2 px-md-3 text-decoration-none"
              :class="link.classes"
              :href="link.itemUrl"
            >
              {{ link.name }}
            </a>
            <v-menu
              v-else
              bottom
              offset-y
              tile
              nudge-bottom="12"
              content-class="children-links-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on.stop="on"
                  class="px-3"
                  :class="link.class"
                >
                  <span class="mr-1">{{ link.name }}</span>
                  <v-icon small color="grey darken-2">mdi-chevron-down</v-icon>
                </a>
              </template>
              <a
                v-for="(templateNavItems, idx2) in link.templateNavItems"
                :key="idx2"
                class="px-4 py-2 white grey--text text--darken-2 text-decoration-none"
                :class="templateNavItems.classes"
                :href="templateNavItems.itemUrl"
                @click="clicked"
              >
                {{ templateNavItems.name }}
              </a>
            </v-menu>
          </div>
        </div>
      </v-row>
    </v-container>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>

<style scoped lang="scss">
.v-app-bar {
  height: auto !important;
  .links {
    background-color: $white;
    a {
      color: $text-color;
      font-size: 13px;
      text-decoration: none;
    }
  }
  .cart-navbar,
  .not-auth {
    width: 100%;
  }
  .nav {
    height: 100%;
    width: 100%;
    background-color: $primary;

    .header-logo {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        height: 50px;
        max-width: 150px;
      }
    }

    .drawer.v-btn {
      background: $secondary;
      min-width: 100px !important;
      width: 100px;
      height: 80px;
      margin-top: -12px;
      margin-bottom: -12px;
      border-radius: 0;
      i {
        color: $white !important;
        font-size: 30px;
      }
      .label {
        color: $white;
        min-height: 7px;
        line-height: 15px;
        font-size: 10px;
      }
    }

    .search {
      .v-btn {
        .label {
          line-height: 14px;
          font-size: 12px;
          color: var(--v-primary-lighten1);
          width: 60px;
          span {
            overflow-x: auto;
            white-space: break-spaces;
          }
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 69px !important;
    .v-btn {
      .label {
        font-size: 11px !important;
        font-weight: bold;
      }
    }
  }

  .cart-info-button {
    background: transparent !important;
    justify-content: flex-start;
    .v-btn__content {
      span {
        font-weight: 400;
        font-size: 13px;
      }
      .v-icon {
        font-size: 12px;
      }
    }

    &.bold {
      .v-btn__content {
        span {
          font-weight: 700;
        }
      }
    }
  }

  .v-progress-linear {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      bottom: -4px !important;
    }
  }
}
.lower-links {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  .prev {
    position: fixed;
    left: 0;
    bottom: 15px;
    &::after {
      border-radius: $border-radius-root;
    }
  }
  .next {
    position: fixed;
    right: 0;
    bottom: 15px;
    &::after {
      border-radius: $border-radius-root;
    }
  }
}
.children-links-menu {
  background: $white !important;
  font-size: 12px !important;
  min-width: 125px !important;
  padding: 5px;
  display: flex;
  flex-direction: column;
  a {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color !important;
    }
  }
}
</style>

<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import FastSearchMenu from "@/components/navigation/FastSearchMenu.vue";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";

import login from "~/mixins/login";
import clickHandler from "~/mixins/clickHandler";
import cartInfo from "~/mixins/cartInfo";

import { mapProposal } from "~/service/ebsn.js";
import CategoryService from "@/commons/service/categoryService";

// import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navbar",
  props: ["links", "user"],
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    MenuNavigator
    // MasonryCategoryMenu
  },
  data() {
    return {
      isMobile: false,
      navbarCategory: null
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    goBack() {
      this.$router.go(-1);
    },
    async loadNavbarCategory() {
      try {
        this.navbarCategory = await CategoryService.getCategoryBySlug(
          "navbar",
          false
        );
        console.log(this.navbarCategory);
      } catch (e) {
        console.log(e);
      }
    },
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    async handleEditTimeslot() {
      await this.openTimeslotSelector();
      console.log("gggg, xtimeslot change");
      global.EventBus.$emit("timeslotchanged");
    },
    openFastSearchDialog() {
      var defaultConfig = {
        toolbarColor: "secondary"
      };
      let properties = {
        waitForResult: true,
        width: 750
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({ showOverlay: "app/showOverlay" }),
    totalItems() {
      return this.cart.totalItems;
    },
    ...mapProposal(
      {
        headerBanner: "header-banner"
      },
      "navbarCategory"
    ),
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  created() {
    this.loadNavbarCategory();
  }
};
</script>
